<template>
  <SuccessModal>
    <h1 class="flex align-center justify-center txt-30 txt-bold txt-font-din-medium mb-20">
      {{ $t('components.titles.pilotComplete') }}
    </h1>
    <p class="txt-16 pl-20 pr-20">
      {{ $t('components.description.congratulationsOnSuccessfullyCompletingThePilot') }}
    </p>
    <div class="flex justify-center w100 pb-40">
      <a-button
        class="mt-24 mr-10 w250 success-button"
        size="large"
        @click="onExit">
        {{ $t('components.description.okGotIt') }}
      </a-button>
    </div>
  </SuccessModal>
</template>

<script>
import SuccessModal from '../../success-modal.vue';

export default {
  name: 'ProjectDashboardPilotSkipJustification',
  components: {
    SuccessModal
  },
  props: {
    onExit: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss">
</style>
